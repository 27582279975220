// White //
$white: #ffffff;
$white-50: #fafbff; //AKA GHOST-WHITE, replacing light-blue-active
$white-100: #f2f5ff; // AKA GHOSTIER-WHITE, replacing light-light-grey and light-blue

// Red //
$red-0: #fcd5d1; //AKA LIGHT-RED,replacing input-error red & amour and lavendar blush
$red-25: #e3245a; // AKA AMARANTH, Primary btn and underline link color
$red-50: #b51c48; // AKA OLD-ROSE, Primary btn hover
$red-75: #ab0000; //AKA DARKER-RED, error-red. delete text, endpoint-red, codev-red and delete red & use in place of $Scarlett

// Blue //
$blue-25: #bcc4e1; // AKA PERIWINKLE, use for $blue-25 too
$blue-50: #224098; // AKA BLUE, use for $resolution-blue
$blue-75: #183e9e; // AKA DEEP-BLUE
$blue-100: #14255b; // AKA SAPPHIRE

// Cyan //
$cyan-0: #e3f7fc; // AKA LIGHT-CYAN, use in place of $alice-light & $azure
$cyan-25: #26b9db; // AKA SUMMER-SKY
$cyan-50: #1e9dba; // AKA PERLOROUS, use in place of $onahau
$cyan-75: #18819d; // AKA CORNFLOWER-BLUE, used on Secondary btn
$cyan-100: #136980; // AKA NEAR-SEAWEED-BLUE, use in place of $production and secondary btn-hover

// Greys //
$grey-20: #f6f9fc; // AKA BACKGROUND-LIGHT, also background-grey and background-sidenav
$grey-40: #ecedf1; // AKA BACKGROUND-MEDIUM, use for input grey classes & default-grey
$grey-60: #9c9b9b; // AKA MEDIUM-GREY, use for classes previously disabled-grey
$grey-80: #6e7083; // AKA STORM-GREY, use for inactive-grey
$grey-80-50: #6e70837f; // $grey-80 at 50% opacity
$grey-100: #2a2f37; // AKA CODE-BLOCK, use for Charcoal dark too

// Text Colors //
$text: #3e405a; // also text-grey
$text-dark: #0e1131; // also black-russian
$text-dark-faded: #4d4d4d;
$text-heading-dark: #3e405d;

// Yellows //
$yellow-0: #fff1d0; // AKA LIGHT-BACKGROUND, formerly patch-background
$yellow-100: #f9c606; // AKA CODEV-GOLD, use in place of $dark-yellow

// Greens //
$green-0: #dae8d8; //AKA LIGHT-GREEN, formerly post-background
$green-50: #008140; //formerly vivid-cyan, and sandbox
$green-100: #00643f; //formerly post-text & used for $production

// Purples//
$purple-0: #f0dff8; // formerly put-background
$purple-100: #6b1d91; // formerly put-text

// Brown//
$brown-100: #894005; // formerly patch-text

//Neutrals AKA Charcoals//
$neutral-0: #d8d9e1; // AKA CHARCOAL-LIGHTEST
$neutral-50: #687680; // AKA CHARCOAL-LIGHT
$neutral-100: #273b49; // AKA CHARCOAL

// Gravity 3 Blue
$gravity-blue: #0070a8;
