/* You can add global styles to this file, and also import other style files */
@import 'colors';
@import 'variables';
@import 'mixins';
@import 'fonts';
@import 'icons';
@import 'components';

/* General Styles */
html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  height: 100%;
  font-family: $font-family-regular;
  line-height: 1.5;

  background-color: $white;
}

/* Header Styles */
h1 {
  font-family: $font-family-regular;
  font-size: 2.25rem;
  margin: 0.5rem 0;
  padding-bottom: 1.25rem;

  @include breakpoint(mobile) {
    font-size: 2rem;
  }
}

h2 {
  font-family: $font-family-light;
  font-size: 2rem;
  font-weight: 300;
  padding-bottom: 1rem;

  @include breakpoint(mobile) {
    font-size: 1.875rem;
    margin: 0.25rem 0;
  }
}

h3 {
  font-family: $font-family-light;

  font-size: 1.5rem;
  padding-bottom: 0.5rem;
}

h4 {
  font-family: $font-family-semibold;
  font-size: 1.25rem; //20px//
  line-height: 1.875rem; //30px//
  padding-bottom: 0.5rem;

  &.blue {
    color: $blue-50;
  }
  &.white {
    color: $white;
  }
}

/* Text Styles */
p {
  font-family: $font-family-regular;
  font-size: 1rem;

  margin: 0.5rem 0;
}

/* Link Styles */
a {
  &:any-link {
    color: $text;

    text-decoration: underline;
    text-decoration-line: underline;
    text-decoration-color: $red-25;

    transition: all ease-in 50ms;

    font-family: $font-family-semibold;
    font-weight: 300;

    &:hover {
      color: $red-25;
    }
  }

  &.no-decorations {
    text-decoration: none;
  }
}

/* Button Styles */
button {
  height: 2.5rem;
  font-size: 1rem;
  font-family: $font-family-semibold;
  padding: 0.5rem 0.75rem;

  border-radius: 0.25rem;
  border-style: solid;

  background-color: $red-25;
  border-color: $red-25;
  color: $white;

  cursor: pointer;

  margin: 0 0.5rem;
  @include breakpoint(mobile) {
    margin: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: $red-50;
    border-color: $red-50;
  }

  &.inverse {
    background-color: $white;
    color: $red-25;

    &:hover {
      background-color: $red-25;
      border-color: $red-25;
      color: $white;
    }
  }

  &.cyan {
    background-color: $cyan-75;
    border-color: $cyan-75;
    color: $white;

    &:hover {
      background-color: $blue-50;
      border-color: $blue-50;
    }
  }

  &.white {
    background-color: $white;
    border-color: $white;
    color: $red-25;

    &:hover {
      background-color: $red-25;
      border-color: $red-25;
      color: $white;
    }

    &.inverse {
      background-color: transparent;
      border-color: $white;
      color: $white;

      &:hover {
        background-color: $white;
        border-color: $white;
        color: $cyan-75;
      }
    }
  }

  &.grey {
    background-color: $neutral-50;
    border-color: $neutral-50;
    color: $white;

    &:hover {
      background-color: $grey-100;
      border-color: $grey-100;
      color: $white;
    }
  }

  &.destructive {
    background-color: $red-75;
    border-color: $red-75;
    color: $white;

    &:hover {
      background-color: $red-75;
      border-color: $red-75;
      color: $white;
    }
  }

  &:disabled {
    background-color: $grey-40;
    border: $grey-40;
    color: $grey-80-50;

    cursor: not-allowed;

    &:hover {
      background-color: $grey-40;
      border: $grey-40;
      color: $grey-80-50;

      cursor: not-allowed;
    }
  }

  &.gravity-blue {
    background-color: $gravity-blue;
    border-color: $white;
    color: $white;
  }
  &.icon {
    border-radius: 0;
    border-style: none;
    background-color: transparent;
    border-color: transparent;
  }
}

.button-group {
  @include flex(row);

  button {
    margin-right: 0.75rem;

    &:last-child {
      margin-right: none;
    }
  }

  &.mobile-row {
    @include breakpoint(mobile) {
      justify-content: space-between;
      button {
        flex-grow: 1;
      }
    }
  }

  &::not(.mobile-row) {
    @include breakpoint(mobile) {
      @include flex(column);
      margin-right: 0;

      button {
        margin-bottom: 0.75rem;

        &:last-child {
          margin-bottom: none;
        }
      }
    }
  }
}

.codev {
  color: $text;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: inline-block;
    margin-right: 0.25rem;
    width: 0.375rem;
    height: 0.375rem;
    -moz-border-radius: 0.1875rem;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
    background-color: $yellow-100;
  }
}

.sandbox {
  color: $green-50;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: inline-block;
    margin-right: 0.25rem;
    width: 0.375rem;
    height: 0.375rem;
    -moz-border-radius: 0.1875rem;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
    background-color: $green-50;
  }
}

.production {
  color: $cyan-100;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: inline-block;
    margin-right: 0.25rem;
    width: 0.375rem;
    height: 0.375rem;
    -moz-border-radius: 0.1875rem;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
    background-color: $cyan-100;
  }
}

/** FORMS **/
form {
  ::placeholder {
    color: $neutral-0;
  }

  textarea {
    font-family: $font-family-regular;
  }

  input {
    background-color: $grey-20;
    border: solid 1px $grey-80;
    border-radius: 0.25rem;

    color: $text;
    font-size: 0.875rem;
    font-family: $font-family-regular;

    padding: 0.5rem;

    height: 40px;
    box-sizing: border-box;

    &.error {
      background-color: $red-0;
      border-color: $red-75;
    }

    &:disabled {
      border-color: $grey-40;
    }

    &[type='checkbox']:not(old) {
      width: 2em;
      margin: 0;
      padding: 0;
      font-size: 1em;
      opacity: 0;
    }

    &[type='checkbox']:not(old) + label {
      display: inline-block;
      margin-left: -2em;
      line-height: 1.4rem;
      cursor: pointer;
    }

    &[type='checkbox']:not(old) + label > span {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin: 0.25em 0.5em 0.25em 0.25em;
      border: 0.0625em solid $grey-80;
      border-radius: 0.25em;
      background: rgb(224, 224, 224);
      background-image: -moz-linear-gradient(
        rgb(240, 240, 240),
        rgb(224, 224, 224)
      );
      background-image: -ms-linear-gradient(
        rgb(240, 240, 240),
        rgb(224, 224, 224)
      );
      background-image: -o-linear-gradient(
        rgb(240, 240, 240),
        rgb(224, 224, 224)
      );
      background-image: -webkit-linear-gradient(
        rgb(240, 240, 240),
        rgb(224, 224, 224)
      );
      background-image: linear-gradient(rgb(240, 240, 240), rgb(224, 224, 224));
      vertical-align: bottom;
    }

    &[type='checkbox']:not(old):checked + label > span {
      background-image: -moz-linear-gradient(
        rgb(224, 224, 224),
        rgb(240, 240, 240)
      );
      background-image: -ms-linear-gradient(
        rgb(224, 224, 224),
        rgb(240, 240, 240)
      );
      background-image: -o-linear-gradient(
        rgb(224, 224, 224),
        rgb(240, 240, 240)
      );
      background-image: -webkit-linear-gradient(
        rgb(224, 224, 224),
        rgb(240, 240, 240)
      );
      background-image: linear-gradient(rgb(224, 224, 224), rgb(240, 240, 240));
    }
  }

  select {
    background-color: $grey-20;
    border: solid 1px $grey-80;
    border-radius: 0.25rem;

    color: $text;
    font-size: 0.875rem;
    font-family: $font-family-regular;
    height: 2.375rem;

    option {
      font-size: 0.875rem;
    }
  }

  label {
    color: $text;
    font-family: $font-family-semibold;
    text-transform: uppercase;
    font-size: 0.75rem;
  }

  textarea {
    border: solid 1px $grey-80;
    border-radius: 0.25rem;
    background-color: $grey-20;
    padding: 0.5rem;
    min-height: 5rem;
    resize: none;
    font-size: 1rem;
  }

  .form-group {
    @include flex(column);
    margin-bottom: 0.25rem;

    &.button-group {
      @include flex(row);

      button {
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .form-heading {
    @include flex(column);
    margin-bottom: 2rem;
    text-align: center;
  }

  .error-message {
    color: $red-75;
    font-style: italic;
    font-size: 0.75rem;
    font-family: $font-family-regular;

    padding-top: 0.25rem;
    visibility: hidden;

    &.show {
      visibility: visible !important;
    }
  }
}

select.devex-carrot {
  width: 100%;
  height: 2rem;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-right: 2rem; // For the carrot space
  overflow-x: scroll;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-image: url('/assets/svg/carat_down.svg');
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
  border-radius: 0.25rem;
  display: inline-block;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
}

.mobile-hidden {
  @include breakpoint(mobile) {
    display: none !important;
  }
}

/* Color Classes */
.white {
  color: $white;
}

.red {
  color: $red-25;
}

/* Layout Styles */
.grid {
  display: grid;
  column-gap: 2rem;
  row-gap: 2rem;

  &.three {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;

    @include breakpoint(tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
    }

    @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 1fr;
    }
  }

  &.two {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;

    @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 1fr;
    }
  }
}
