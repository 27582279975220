// https://github.cloud.capitalone.com/Gravity/optimist

$font-url: '/assets/fonts';

@font-face {
  font: {
    family: 'Optimist';
    weight: normal;
  }
  font-display: block;
  src: url('#{$font-url}/Optimist_W_Rg.eot?#iefix');
  src: url('#{$font-url}/Optimist_W_Rg.eot?#iefix') format('eot'),
    url('#{$font-url}/Optimist_W_Rg.woff2') format('woff2'),
    url('#{$font-url}/Optimist_W_Rg.woff') format('woff');
}

@font-face {
  font: {
    family: 'Optimist Semibold';
    weight: normal;
  }
  font-display: block;
  src: url('#{$font-url}/Optimist_W_SBd.eot?#iefix');
  src: url('#{$font-url}/Optimist_W_SBd.eot?#iefix') format('eot'),
    url('#{$font-url}/Optimist_W_SBd.woff2') format('woff2'),
    url('#{$font-url}/Optimist_W_SBd.woff') format('woff');
}

@font-face {
  font: {
    family: 'Optimist Bold';
    weight: normal;
  }
  font-display: block;
  src: url('#{$font-url}/Optimist_W_Bd.eot?#iefix');
  src: url('#{$font-url}/Optimist_W_Bd.eot?#iefix') format('eot'),
    url('#{$font-url}/Optimist_W_Bd.woff2') format('woff2'),
    url('#{$font-url}/Optimist_W_Bd.woff') format('woff');
}

@font-face {
  font: {
    family: 'Optimist Light';
    weight: normal;
    style: normal;
  }
  font-display: block;
  src: url('#{$font-url}/Optimist_W_Lt.eot');
  src: url('#{$font-url}/Optimist_W_Lt.eot?#iefix') format('embedded-opentype'),
    url('#{$font-url}/Optimist_W_Lt.woff2') format('woff2'),
    url('#{$font-url}/Optimist_W_Lt.woff') format('woff');
}

@font-face {
  font: {
    family: 'Optimist ExtraLight';
    weight: normal;
    style: normal;
  }
  font-display: block;
  src: url('#{$font-url}/Optimist_W_XLt.eot');
  src: url('#{$font-url}/Optimist_W_XLt.eot?#iefix') format('embedded-opentype'),
    url('#{$font-url}/Optimist_W_XLt.woff2') format('woff2'),
    url('#{$font-url}/Optimist_W_XLt.woff') format('woff');
}

$font-family-extralight: 'Optimist ExtraLight', Arial, Helvetica, sans-serif;
$font-family-light: 'Optimist light', Arial, Helvetica, sans-serif;
$font-family-regular: 'Optimist', Arial, Helvetica, sans-serif;
$font-family-semibold: 'Optimist Semibold', Arial, Helvetica, sans-serif;
$font-family-bold: 'Optimist Bold', Arial, Helvetica, sans-serif;
