@import '_colors';
@import 'fonts';
@import 'mixins';

.card {
  background-color: $white;
  padding: 1.5rem;

  border-radius: 0.2rem;
  border: 1px solid $neutral-0;

  box-shadow: 0 1px 4px 0 $neutral-0;

  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'card-image'
    'card-header'
    'card-text'
    'card-bottom-container';

  .card-image {
    margin: -1.5rem -1.5rem 1.5rem -1.5rem;
    grid-area: card-image;
  }

  h3.card-header {
    font-family: $font-family-regular;
    margin: 0;

    word-wrap: normal;
    width: 100%;
    grid-area: card-header;
  }

  .card-text {
    font-family: $font-family-regular;
    color: $text;
    font-size: 1rem;
    line-height: 1.5;
    word-wrap: normal;

    width: 100%;
    margin: 0.5rem 0;

    grid-area: card-text;
  }

  .card-bottom-container {
    @include flex(row);
    margin-top: 0.5rem;

    grid-area: card-bottom-container;

    & > * {
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      text-transform: uppercase;
      font-family: $font-family-light;
      font-size: 0.75rem;
      color: $text;

      text-decoration: none;
    }

    &.buttons {
      @include breakpoint(mobile) {
        @include flex(column);

        button {
          margin: 0.5rem 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
