@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot');
  src: url('/assets/fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.woff2') format('woff2'),
    url('/assets/fonts/icomoon.ttf') format('truetype'),
    url('/assets/fonts/icomoon.woff') format('woff'),
    url('/assets/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
.dx-copy {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  padding: 0 0.375rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dx_logoMark:before {
  content: '\e900';
}
.icon-dx-application:before {
  content: '\e901';
}
.icon-dx-arrow-right:before {
  content: '\e902';
}
.icon-dx-avatar:before {
  content: '\e903';
}
.icon-dx-beaker:before {
  content: '\e904';
}
.icon-dx-bookOpen:before {
  content: '\e905';
}
.icon-dx-carat-down:before {
  content: '\e906';
}
.icon-dx-carat-left:before {
  content: '\e907';
}
.icon-dx-carat-right:before {
  content: '\e908';
}
.icon-dx-carat-up:before {
  content: '\e909';
}
.icon-dx-caution-outline:before {
  content: '\e90a';
}
.icon-dx-close:before {
  content: '\e90b';
}
.dx-copy:not(.copied):before,
.icon-dx-copy:not(.copied):before {
  content: '\e90c';
}
.dx-copy.copied:before,
.icon-dx-copy.copied:before {
  content: '\e923';
}
.icon-dx-dashboard:before {
  content: '\e90d';
}
.icon-dx-docs:before {
  content: '\e90e';
}
.icon-dx-download:before {
  content: '\e90f';
}
.icon-dx-email-circle .path1:before {
  content: '\e910';
  color: rgb(62, 64, 90);
}
.icon-dx-email-circle .path2:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(247, 248, 249);
}
.icon-dx-endArrow-left:before {
  content: '\e912';
}
.icon-dx-endArrow-right:before {
  content: '\e913';
}
.icon-dx-feedback:before {
  content: '\e914';
}
.icon-dx-github-logo:before {
  content: '\e915';
}
.icon-dx-hamburger_menu:before {
  content: '\e916';
  color: #14255b;
}
.icon-dx-help-outlineCircle:before {
  content: '\e917';
}
.icon-dx-important-outline:before {
  content: '\e918';
}
.icon-dx-information:before {
  content: '\e919';
}
.icon-dx-link-out:before {
  content: '\e91a';
}
.icon-dx-lock:before {
  content: '\e91b';
}
.icon-dx-magnifying-glass:before {
  content: '\e91c';
}
.icon-dx-pause:before {
  content: '\e91d';
}
.icon-dx-pencil:before {
  content: '\e91e';
}
.icon-dx-play:before {
  content: '\e91f';
}
.icon-dx-resources:before {
  content: '\e920';
}
.icon-dx-rows-outline:before {
  content: '\e921';
}
.icon-dx-rows-solid:before {
  content: '\e922';
}
.icon-dx-success:before {
  content: '\e923';
}
.icon-dx-tiles-solid:before {
  content: '\e924';
}
.icon-dx-trash:before {
  content: '\e925';
}
.icon-dx-triangle-left:before {
  content: '\e926';
}
.icon-dx-triangle-right:before {
  content: '\e927';
}
.icon-dx-unlock-lock:before {
  content: '\e928';
}
.icon-dx-upload-circle:before {
  content: '\e929';
}
.icon-Step1:before {
  content: '\e92a';
  color: #14255b;
}
.icon-Step2:before {
  content: '\e92b';
  color: #0e2a54;
}
.icon-Step3:before {
  content: '\e92c';
  color: #0e2a54;
}
