@mixin breakpoint($point) {
  @if ($point == desktop) {
    @media only screen and (min-width: 70rem) {
      @content;
    }
  } @else if ($point == tablet) {
    @media only screen and (max-width: 69.9375rem) {
      @content;
    }
  } @else if ($point == mobile) {
    @media only screen and (max-width: 47.9375rem) {
      @content;
    }
  }
}

@mixin flex($orientation) {
  display: flex;
  flex-direction: $orientation;
}

@mixin full-background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

@mixin red-arrow {
  &:after {
    content: url('/assets/svg/arrow_right.svg');
    display: inline-block;
    margin-left: 0.25rem;
    transform: translateY(-2px);
  }
}

@mixin red-arrow-left {
  &:before {
    content: url('/assets/svg/arrow_right.svg');
    display: inline-block;
    margin-right: 0.25rem;
    transform: scaleX(-1);
  }
}
